import React from 'react';
import Layout from '../components/layout/Layout';
import Content from '../content/terms-content.json'

const formatChapter = (chapter) => (
    <div className='my-8 '>
        <li key={chapter.ref} className='text-2xl font-semibold pb-4'>{chapter.title}</li>
        
        {chapter.content.textBefore.map(paragraph => (
            <p>{paragraph}</p>
        ))}
        {
            chapter.content.ul !== undefined ?
                <ul>
                    {chapter.content.ul.map((item) => (
                        <li>{item}</li>
                    ))}
                </ul> :
                <React.Fragment />
        }
        {
            chapter.content.textAfter !== undefined ?
                chapter.content.textAfter.map(par => (<p>{par}</p>)) :
                <React.Fragment />
        }
    </div>
)


const terms = () => {
    return (
        <Layout>
            <section className='flex flex-col items-center'>
                <div className='container flex flex-col px-8 sm:px-16 md:px-24 xl:px-32'>
                    <h1 className='flex font-bold text-7xl my-16'>Terms of Use</h1>
                    <div className='flex flex-col gap-y-8'>
                        <ol>
                            {Content.map(q => formatChapter(q))}
                        </ol>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default terms;
